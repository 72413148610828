import React, { useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';

import { FormPageLayout } from '../components/Layouts';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title } = Typography;
const strings = new LocalizedStrings({
    en: {
        initialized: 'Platform has been initialized',
        initialization: 'Platform Initialization',
        superAdminEmail: 'Super Admin E-mail',
        enterEmail: 'Please enter your email',
        superAdminPassword: 'Super Admin Password',
        enterPassword: 'Please enter your password',
        superAdminFirstName: 'Super Admin First Name',
        enterFirstName: 'Please enter your first name',
        superAdminLastName: 'Super Admin Last Name',
        enterLastName: 'Please enter your last name',
        initialize: 'Initialize',
    },
    de: {
        initialized: 'Plattform wurde initialisiert',
        initialization: 'Initialisierung der Plattform',
        superAdminEmail: 'E-Mail-Adresse Super-Admin',
        enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        superAdminPassword: 'Passwort Super-Admin',
        enterPassword: 'Bitte geben Sie Ihr Passwort ein',
        superAdminFirstName: 'Vorname Super-Admin',
        enterFirstName: 'Bitte geben Sie Ihren Vornamen ein',
        superAdminLastName: 'Nachname Super-Admin',
        enterLastName: 'Bitte geben Sie Ihren Nachnamen ein',
        initialize: 'Initialisierung',
    },
});

const InitPage = () => {
    const [loading, setLoading] = useState(false);
    const onFinish = async (values: { emailId: string; password: string; firstName: string; lastName: string }) => {
        try {
            setLoading(true);
            const response = await apiRequester.init(values);
            console.info(response);
            handleSuccess(strings.initialized!);
            navigate('/login');
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    return (
        <FormPageLayout>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Title level={2} style={{ marginBottom: '1.5rem' }}>
                    {strings.initialization}
                </Title>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={strings.superAdminEmail}
                    labelCol={{ span: 24 }}
                    name="emailId"
                    rules={[{ required: true, type: 'email', message: strings.enterEmail }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={strings.superAdminPassword}
                    labelCol={{ span: 24 }}
                    name="password"
                    rules={[{ required: true, message: strings.enterPassword }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={strings.superAdminFirstName}
                    labelCol={{ span: 24 }}
                    name="firstName"
                    rules={[{ required: true, message: strings.enterFirstName }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={strings.superAdminLastName}
                    labelCol={{ span: 24 }}
                    name="lastName"
                    rules={[{ required: true, message: strings.enterLastName }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button block={true} type="primary" htmlType="submit" loading={loading}>
                        {strings.initialize}
                    </Button>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default InitPage;
