import React, { ReactNode, useContext, useEffect } from 'react';

import { Layout as AntdLayout } from 'antd';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';
import styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContextProvider';
import Menu from '../Menu';

const { Header, Content, Footer } = AntdLayout;

const StyledHeader = styled(Header)`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 4rem;
`;

export const SidebarLayout = ({ children, level = 0 }: { children: ReactNode; level?: number }) => {
    // const pubnub = usePubNub();
    // const context = useContext(GlobalContext);
    // const defaultEventChannels = [`users.${context.user?._id}`];

    // const presenceHandler = (presenceEvent: Pubnub.PresenceEvent) => {
    //     console.log('presence event', presenceEvent);
    // };

    // const statusHandler = (statusEvent: Pubnub.StatusEvent) => {
    //     console.log('status event', statusEvent);
    // };

    // const pubnubListener = { presence: presenceHandler, status: statusHandler };

    // useEffect(() => {
    //     pubnub.addListener(pubnubListener);
    //     pubnub.subscribe({ channels: defaultEventChannels });
    //     return () => {
    //         pubnub.removeListener(pubnubListener);
    //         pubnub.unsubscribe({ channels: defaultEventChannels });
    //     };
    // }, [context.activeEvent]);

    return (
        <AntdLayout className="layout" style={{ height: '100vh', overflowY: 'hidden' }}>
            <StyledHeader>
                <div style={{ float: 'left', width: '120px', color: 'white' }}>
                    <strong>VIRTOOALLY</strong>
                </div>
                <Menu />
            </StyledHeader>
            <Content style={{ flexGrow: 1, display: 'flex', marginLeft: `${level * 20}vw` }}>{children}</Content>
            {/* <Footer style={{ textAlign: 'center' }}>
                Virtooally by{' '}
                <a href="https://www.exponents.com/" target="_blank" rel="noreferrer">
                    Exponents Insta USA Inc.
                </a>
            </Footer> */}
        </AntdLayout>
    );
};

export default SidebarLayout;
