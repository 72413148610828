import React, { ReactNode } from 'react';

import { Col, Layout as AntdLayout, Row, Typography } from 'antd';
import LocalizedStrings from 'react-localization';
import styled from 'styled-components';

const { Content, Footer } = AntdLayout;
const { Title, Paragraph } = Typography;

const strings = new LocalizedStrings({
    en: {
        interactiveVirtualSystem: 'Interactive Virtual Exhibits System',
        aboutVirtooally:
            'An immersive virtual events and exhibit platform that enables you to customize and showcase your marketing message to a global audience and bring them close to a real on ground experience.',
        by: 'by',
    },
    de: {
        interactiveVirtualSystem: 'Interaktive virtuelles Ausstellungssystem',
        aboutVirtooally:
            'Eine eingebettete virtuelle Veranstaltungs- und Ausstellungsplattform, die es Ihnen ermöglicht, Ihre Marketingbotschaft anzupassen und einem globalen Publikum zu präsentieren und es einem echten Erlebnis vor Ort nahe zu bringen.',
        by: 'von',
    },
});

const SideHero = styled.div`
    background: url('/sample-event.jpg') no-repeat fixed center;
    height: 100vh;
    filter: blur(8px);
    -webkit-filter: blur(8px);
`;

const SideHeroOverlay = styled.div`
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: auto;
    top: 0px;
    left: 0px;
    background: linear-gradient(rgba(24, 83, 219, 0.9), rgba(0, 0, 0, 0.8));
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
`;

const FormArea = styled.div`
    text-align: left;
    padding: 4rem;
`;

const StyledFooter = styled(Footer)`
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    text-align: right;
    background: transparent;
`;

export const FormPageLayout = ({ children }: { children: ReactNode }) => {
    return (
        <AntdLayout className="layout" style={{ minHeight: '100vh' }}>
            <Content>
                <Row align="middle" justify="space-around">
                    <Col xs={0} md={10}>
                        <SideHero />
                        <SideHeroOverlay>
                            <Title level={1} style={{ color: 'white' }}>
                                Virtooally
                            </Title>
                            <Paragraph style={{ color: 'white' }}>{strings.interactiveVirtualSystem}</Paragraph>
                            <Paragraph
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '3rem',
                                    width: '50%',
                                }}
                            >
                                {strings.aboutVirtooally}
                            </Paragraph>
                        </SideHeroOverlay>
                    </Col>
                    <Col xs={20} md={14}>
                        <FormArea>{children}</FormArea>
                    </Col>
                </Row>
                <StyledFooter>
                    Virtooally {strings.by}{' '}
                    <a href="https://www.exponents.com/" target="_blank" rel="noreferrer">
                        Exponents Insta USA Inc.
                    </a>
                </StyledFooter>
            </Content>
        </AntdLayout>
    );
};

export default FormPageLayout;
